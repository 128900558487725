<template>
  <WsMain v-if="isMounted">
    <WsInfoForm
      :fields="fields"
      v-model="state.content"
    ></WsInfoForm>
  </WsMain>
</template>

<script>
import S_SystemInfo from "@/__modules/system/services/system_info";
export default {
  data() {
    return {
      isMounted: false,
      state: {
        content: {},
      },
      fields: {
        version_web: {
          label: "前台網站版本",
        },
        version_api: {
          label: "前台 API 版本",
        },
        version_cms: {
          label: "後台版本",
        },
        version_cms_api: {
          label: "後台 API 版本",
        },
      },
    };
  },
  methods: {
    async $_valueSet() {
      const res = await S_SystemInfo.show();
      this.state.content = res.content;
      this.isMounted = true;
    },
  },
  mounted() {
    this.$_valueSet();
  },
};
</script>

<style>
</style>